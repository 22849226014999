import React from 'react';
import { useTranslation } from "react-i18next";

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import TopHeading from '../components/TopHeading';
import ListOfManufacturers from '../components/ListOfManufacturers';
import CenterText from '../components/CenterText';
import Divider from '../components/Divider';

import BgImage from '../assets/images/manufacturers-bg.jpg';

const Manufacturers = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <Header />
            <TopHeading heading={t("manufacturers.main_heading")} bgImage={BgImage} bgPositionY={'70%'} />
            <CenterText text={t("manufacturers.text")} />
            <Divider text={t("manufacturers.heading")} />
            <ListOfManufacturers />
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
                    representativeStore={t("contacts.representativeStore")}
            />
        </>
    );

}

export default Manufacturers;