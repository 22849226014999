import React from 'react';
import { useTranslation } from "react-i18next";

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import TopHeading from '../components/TopHeading';
import Divider from '../components/Divider';
import SideImageAndText from '../components/SideImageAndText';

import BgImage from '../assets/images/investments-bg.jpg';
import SideImage from '../assets/images/invest-img.jpg';

const Investments = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <Header />
            <TopHeading heading={t("investments.main_heading")} bgImage={BgImage} bgPositionY={'0%'} />
            <Divider text={t("investments.heading")} />
            <SideImageAndText img={SideImage} showContactBtn={true} alt={'Investments'} text={t("investments.text")} />
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
                    representativeStore={t("contacts.representativeStore")}
            />
        </>
    );

}

export default Investments;