import React from 'react';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Table from '../components/Table';
import {useTranslation} from "react-i18next";


const AdminPanelPage = () => {
    const {t} = useTranslation("global");

    return (
        <>
            <Header />
            <Table showAdminPanel={false} />
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
                    representativeStore={t("contacts.representativeStore")}
            />
        </>
    );

}

export default AdminPanelPage;