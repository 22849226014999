import React, { useEffect, useState } from "react";
import { Wrapper, AdminPanelContainer, AdminPanel, Button, TableContainerWrapper, TableContainer } from "./Table.styles";
import Input from "../Input";
import Modal from "../Modal";

const Table = ({ showAdminPanel }) => {
    const [wines, setWines] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    function getWines() {
        fetch("https://winecraft.ee/api/getWines", {
            method: 'GET',
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("HTTP response not ok");
                }
            })
            .then(json => {
                setWines(json);
            })
            .catch(error => {
                alert("Error: " + error.message);
            });
    }

    useEffect(() => {
        getWines();
    }, []);

    const highlightSearchTerm = (value) => {
        const text = value.toString();

        if (typeof text !== 'string') {
            return text;
        }

        const lowerSearchTerm = searchTerm.toLowerCase();
        const index = text.toLowerCase().indexOf(lowerSearchTerm);

        if (index !== -1) {
            return (
                <>
                    {text.substring(0, index)}
                    <span style={{ color: 'black', fontWeight: '600' }}>{text.substring(index, index + searchTerm.length)}</span>
                    {text.substring(index + searchTerm.length)}
                </>
            );
        } else {
            return text;
        }
    };
    function filterWines(wines, searchTerm) {
        if (wines.length === 0) {
            return [];
        }

        return wines.filter(wine =>
            ["name", "price"].some(field =>
                wine[field] && wine[field].toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }

    function formatPrice(value) {
        // Round to two decimal places but convert to integer if the decimal part is zero
        const roundedValue = Math.floor(value * 100) / 100; // Floors to 2 decimal places
        return roundedValue % 1 === 0
            ? `${roundedValue.toFixed(0)}€`        // No decimal places for whole numbers
            : `${roundedValue.toFixed(2)}€`;       // Two decimal places for non-whole numbers
    }


    const filteredWines = filterWines(wines, searchTerm);

    const [isImportWineModalOpen, setImportWineModalOpen] = useState(false);
    const [isAddWineModalOpen, setAddWineModalOpen] = useState(false);

    const openImportWineModal = () => {
        setImportWineModalOpen(true);
    };

    const openAddWineModal = () => {
        setAddWineModalOpen(true);
    };

    const handleClose = () => {
        setImportWineModalOpen(false);
        setAddWineModalOpen(false);
    };

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    };

    const handleUploadButtonClick = () => {
        selectedFiles.forEach((file) => {
            const formData = new FormData();
            formData.append('file', file);

            fetch("http://localhost:8080/api/csv/wines", {
                method: 'POST',
                body: formData,
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("HTTP response not ok");
                }
            })
            .catch(error => {
                alert("Error: " + error.message);
            });
        });
    };

    return (
        <Wrapper>
            <div>{filteredWines.length}</div>
            <AdminPanelContainer>
                <Input
                    style={{padding: '8px', width: '300px'}}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {showAdminPanel &&
                    <AdminPanel>
                        <Button>Export wines</Button>
                        <Button onClick={openImportWineModal}>Import wines</Button>
                        <Button onClick={openAddWineModal}>Add wine</Button>
                    </AdminPanel>
                }
            </AdminPanelContainer>
            <TableContainerWrapper>
                <TableContainer>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredWines.map(wine => (
                        <tr>
                            <td>{highlightSearchTerm(wine.name)}</td>
                            <td>{highlightSearchTerm(formatPrice(wine.price))}</td>
                        </tr>
                    ))}
                    </tbody>
                </TableContainer>
            </TableContainerWrapper>

            <Modal header={"Import Wines"} isOpen={isImportWineModalOpen} onClose={handleClose}>
                <div>
                    <label htmlFor="wineFile">Choose wine files:</label><br/>
                    <input type="file" id="wineFile" onChange={handleFileChange} multiple/>
                    <Button onClick={handleUploadButtonClick} style={{marginTop: "40px"}}>Upload files</Button>
                </div>
            </Modal>
            <Modal header={"Add Wine"} isOpen={isAddWineModalOpen} onClose={handleClose}>
                <h2>Modal 2</h2>
                <p>Add wine.</p>
            </Modal>
        </Wrapper>
    );
};

export default Table;
