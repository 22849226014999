import React from 'react';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from '../components/Login';
import {useTranslation} from "react-i18next";

const HomePage = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <Header />
            <Login />n
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
                    representativeStore={t("contacts.representativeStore")}
            />
        </>
    );

}

export default HomePage;