import React from 'react';
import {useTranslation} from "react-i18next";

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactInfo from '../components/ContactInfo';
import TopHeading from '../components/TopHeading';
import Divider from '../components/Divider';
import Team from '../components/Team';

import BgImage from '../assets/images/contacts-bg.jpg';

const Contacts = () => {
    const {t} = useTranslation("global");

    return (
        <>
            <Header/>
            <TopHeading heading={t("contacts.main_heading")} bgImage={BgImage} bgPositionY={'50%'}/>
            <ContactInfo
                representativeStore={t("contacts.representativeStore")}
                wineLibrary={t("contacts.wineLibrary")}
                warehouse={t("contacts.warehouse")}
                registerCode={t("contacts.registerCode")}
                kmkrNr={t("contacts.kmkrNr")}
            />
            <Divider text={t("contacts.heading")}/>
            <Team/>
            <Footer registerCode={t("contacts.registerCode")}
                    kmkrNr={t("contacts.kmkrNr")}
                    representativeStore={t("contacts.representativeStore")}
            />

        </>
    );

}

export default Contacts;